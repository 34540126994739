<template>
  <div class="analysis-tfoms__table analysis-tfoms__base-table">
    <base-table-full
      :dataSource="data"
      :headers="type === 1 ? headers1 : headers2"
      hide-default-footer
      notShowSelect
      nested
      not-filter
      @click:row="click"
      :item-class="
        (item) => (value && item.Id === value.Id ? 'table-item--active' : '')
      "
      :sort-by="['Value']"
      :sort-desc="[true]"
      :exclude-settings-actions="['table', 'group']"
    >
      <template slot="item.DiffValue" slot-scope="{ item }">
        <m-icon
          v-if="item.DiffValue > 0"
          color="error"
          :icon="'mdi-arrow-up-thin'"
        /><m-icon
          v-if="item.DiffValue < 0"
          color="success"
          :icon="'mdi-arrow-down-thin'"
        />
      </template>
    </base-table-full>
  </div>
</template>
<script>
import moment from "moment";

export default {
  components: {
    BaseTableFull: () => import("@/components/base/BaseTableFull"),
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: 1,
    },
    MonthsCount: {
      type: Number,
      default: parseInt(moment().format("MM")),
    },
    month: { type: String, default: null },
  },
  data() {
    return {
      headers1: [
        {
          value: "Object",
          text: "Медицинская организация",
          dataType: "object",
          displayText: (e) => e.Name,
          cellClass: "px-0",
        },
        {
          value: "Value",
          text: "Нежелательные события",
          dataType: "numberBar",
          cellClass: "px-0",
        },
        {
          value: "Id",
          text: "Период",
          displayText: () => this.month || this.MonthsCount + " мес.",
        },
        {
          value: "DiffValue",
          text: "",
          cellClass: "px-0",
          width: "20px",
        },
      ],
      headers2: [
        {
          value: "Type",
          text: "Виды НС",
          dataType: "object",
          displayText: (e) => e.Name,
          cellClass: "px-0",
        },
        {
          value: "Value",
          text: "Нежелательные события",
          dataType: "numberBar",
          cellClass: "px-0",
        },
        {
          value: "Id",
          text: "Период",
          displayText: () => this.month || this.MonthsCount + " мес.",
        },
        {
          value: "DiffValue",
          text: "",
          cellClass: "px-0",
          width: "20px",
        },
      ],
    };
  },
  methods: {
    click(val) {
      this.$emit("input", val?.Id === this.value?.Id ? null : val);
    },
  },
};
</script>
